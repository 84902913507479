/**
 * THIS IS AN AUTO-GENERATED FILE, DO NOT EDIT MANUALLY
 */
import IcoAndroidColored16 from '../ico/ico-android-colored-16';
import IcoAndroidColored24 from '../ico/ico-android-colored-24';
import IcoAppleColored16 from '../ico/ico-apple-colored-16';
import IcoAppleColored24 from '../ico/ico-apple-colored-24';
import IcoAppleColored40 from '../ico/ico-apple-colored-40';
import IcoArrowDown16 from '../ico/ico-arrow-down-16';
import IcoArrowDown24 from '../ico/ico-arrow-down-24';
import IcoArrowLeftSmall16 from '../ico/ico-arrow-left-small-16';
import IcoArrowLeftSmall24 from '../ico/ico-arrow-left-small-24';
import IcoArrowRightSmall16 from '../ico/ico-arrow-right-small-16';
import IcoArrowRightSmall24 from '../ico/ico-arrow-right-small-24';
import IcoArrowUp16 from '../ico/ico-arrow-up-16';
import IcoArrowUp24 from '../ico/ico-arrow-up-24';
import IcoArrowUpRight16 from '../ico/ico-arrow-up-right-16';
import IcoArrowUpRight24 from '../ico/ico-arrow-up-right-24';
import IcoBolt16 from '../ico/ico-bolt-16';
import IcoBolt24 from '../ico/ico-bolt-24';
import IcoBuilding16 from '../ico/ico-building-16';
import IcoBuilding24 from '../ico/ico-building-24';
import IcoCake16 from '../ico/ico-cake-16';
import IcoCake24 from '../ico/ico-cake-24';
import IcoCheck16 from '../ico/ico-check-16';
import IcoCheck24 from '../ico/ico-check-24';
import IcoCheckCircle16 from '../ico/ico-check-circle-16';
import IcoCheckCircle24 from '../ico/ico-check-circle-24';
import IcoCheckCircle40 from '../ico/ico-check-circle-40';
import IcoCheckSmall16 from '../ico/ico-check-small-16';
import IcoCheckSmall24 from '../ico/ico-check-small-24';
import IcoChevronDown16 from '../ico/ico-chevron-down-16';
import IcoChevronDown24 from '../ico/ico-chevron-down-24';
import IcoChevronLeft16 from '../ico/ico-chevron-left-16';
import IcoChevronLeft24 from '../ico/ico-chevron-left-24';
import IcoChevronLeftBig16 from '../ico/ico-chevron-left-big-16';
import IcoChevronLeftBig24 from '../ico/ico-chevron-left-big-24';
import IcoChevronRight16 from '../ico/ico-chevron-right-16';
import IcoChevronRight24 from '../ico/ico-chevron-right-24';
import IcoClipboard16 from '../ico/ico-clipboard-16';
import IcoClipboard24 from '../ico/ico-clipboard-24';
import IcoClose16 from '../ico/ico-close-16';
import IcoClose24 from '../ico/ico-close-24';
import IcoCloseSmall16 from '../ico/ico-close-small-16';
import IcoCloseSmall24 from '../ico/ico-close-small-24';
import IcoCode16 from '../ico/ico-code-16';
import IcoCode24 from '../ico/ico-code-24';
import IcoCrosshair16 from '../ico/ico-crosshair-16';
import IcoCrosshair24 from '../ico/ico-crosshair-24';
import IcoDatabase16 from '../ico/ico-database-16';
import IcoDatabase24 from '../ico/ico-database-24';
import IcoDotsHorizontal16 from '../ico/ico-dots-horizontal-16';
import IcoDotsHorizontal24 from '../ico/ico-dots-horizontal-24';
import IcoEmail16 from '../ico/ico-email-16';
import IcoEmail24 from '../ico/ico-email-24';
import IcoEye16 from '../ico/ico-eye-16';
import IcoEye24 from '../ico/ico-eye-24';
import IcoFileText216 from '../ico/ico-file-text-2-16';
import IcoFileText224 from '../ico/ico-file-text-2-24';
import IcoFileText16 from '../ico/ico-file-text-16';
import IcoFileText24 from '../ico/ico-file-text-24';
import IcoFootprint16 from '../ico/ico-footprint-16';
import IcoFootprint24 from '../ico/ico-footprint-24';
import IcoFootprint40 from '../ico/ico-footprint-40';
import IcoGoogleColored16 from '../ico/ico-google-colored-16';
import IcoGoogleColored24 from '../ico/ico-google-colored-24';
import IcoHelp16 from '../ico/ico-help-16';
import IcoHelp24 from '../ico/ico-help-24';
import IcoInfo16 from '../ico/ico-info-16';
import IcoInfo24 from '../ico/ico-info-24';
import IcoLock16 from '../ico/ico-lock-16';
import IcoLock24 from '../ico/ico-lock-24';
import IcoLogOut16 from '../ico/ico-log-out-16';
import IcoLogOut24 from '../ico/ico-log-out-24';
import IcoMenu16 from '../ico/ico-menu-16';
import IcoMenu24 from '../ico/ico-menu-24';
import IcoPhone16 from '../ico/ico-phone-16';
import IcoPhone24 from '../ico/ico-phone-24';
import IcoPlusBig16 from '../ico/ico-plus-big-16';
import IcoPlusBig24 from '../ico/ico-plus-big-24';
import IcoPlusSmall16 from '../ico/ico-plus-small-16';
import IcoPlusSmall24 from '../ico/ico-plus-small-24';
import IcoQuote40 from '../ico/ico-quote-40';
import IcoSearch16 from '../ico/ico-search-16';
import IcoSearch24 from '../ico/ico-search-24';
import IcoSearchSmall16 from '../ico/ico-search-small-16';
import IcoSearchSmall24 from '../ico/ico-search-small-24';
import IcoSettings16 from '../ico/ico-settings-16';
import IcoSettings24 from '../ico/ico-settings-24';
import IcoShield16 from '../ico/ico-shield-16';
import IcoShield24 from '../ico/ico-shield-24';
import IcoSpinner16 from '../ico/ico-spinner-16';
import IcoSpinner24 from '../ico/ico-spinner-24';
import IcoUser16 from '../ico/ico-user-16';
import IcoUser24 from '../ico/ico-user-24';
import IcoUserCircle16 from '../ico/ico-user-circle-16';
import IcoUserCircle24 from '../ico/ico-user-circle-24';
import IcoUsers16 from '../ico/ico-users-16';
import IcoUsers24 from '../ico/ico-users-24';

export default {
  IcoAndroidColored16,
  IcoAppleColored16,
  IcoArrowDown16,
  IcoArrowLeftSmall16,
  IcoArrowRightSmall16,
  IcoArrowUp16,
  IcoArrowUpRight16,
  IcoBolt16,
  IcoBuilding16,
  IcoCake16,
  IcoCheck16,
  IcoCheckCircle16,
  IcoCheckSmall16,
  IcoChevronDown16,
  IcoChevronLeft16,
  IcoChevronLeftBig16,
  IcoChevronRight16,
  IcoClipboard16,
  IcoClose16,
  IcoCloseSmall16,
  IcoCode16,
  IcoCrosshair16,
  IcoDatabase16,
  IcoDotsHorizontal16,
  IcoEmail16,
  IcoEye16,
  IcoFileText16,
  IcoFileText216,
  IcoFootprint16,
  IcoGoogleColored16,
  IcoHelp16,
  IcoInfo16,
  IcoLock16,
  IcoLogOut16,
  IcoMenu16,
  IcoPhone16,
  IcoPlusBig16,
  IcoPlusSmall16,
  IcoSearch16,
  IcoSearchSmall16,
  IcoSettings16,
  IcoShield16,
  IcoSpinner16,
  IcoUser16,
  IcoUserCircle16,
  IcoUsers16,
  IcoAndroidColored24,
  IcoAppleColored24,
  IcoArrowDown24,
  IcoArrowLeftSmall24,
  IcoArrowRightSmall24,
  IcoArrowUp24,
  IcoArrowUpRight24,
  IcoBolt24,
  IcoBuilding24,
  IcoCake24,
  IcoCheck24,
  IcoCheckCircle24,
  IcoCheckSmall24,
  IcoChevronDown24,
  IcoChevronLeft24,
  IcoChevronLeftBig24,
  IcoChevronRight24,
  IcoClipboard24,
  IcoClose24,
  IcoCloseSmall24,
  IcoCode24,
  IcoCrosshair24,
  IcoDatabase24,
  IcoDotsHorizontal24,
  IcoEmail24,
  IcoEye24,
  IcoFileText224,
  IcoFileText24,
  IcoFootprint24,
  IcoGoogleColored24,
  IcoHelp24,
  IcoInfo24,
  IcoLock24,
  IcoLogOut24,
  IcoMenu24,
  IcoPhone24,
  IcoPlusBig24,
  IcoPlusSmall24,
  IcoSearch24,
  IcoSearchSmall24,
  IcoSettings24,
  IcoShield24,
  IcoSpinner24,
  IcoUser24,
  IcoUserCircle24,
  IcoUsers24,
  IcoAppleColored40,
  IcoCheckCircle40,
  IcoFootprint40,
  IcoQuote40,
};
